import { Box, Collapse, makeStyles } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { FC } from "react";

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    content: {
      ...theme.typography.body2,
      backgroundColor: theme.colors.softGrey,
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "center",
      lineHeight: 1.3,
      minHeight: 42,
      padding: theme.spacing(.5, 2),
      textAlign: "center",

      "& strong": {
        display: "inline-block",
        margin: theme.spacing(0, .5),
        padding: theme.spacing(0, .5),
        borderRadius: theme.spacing(0.5),
        backgroundColor: theme.colors.warning.main,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  }),
  {
    classNamePrefix: "Banner",
  }
);

export type BannerJSSClassKey = keyof ReturnType<typeof useStyles>;

export type BannerProps = {
  classes?: Partial<ClassNameMap<BannerJSSClassKey>>;
  className?: string;
  in?: boolean;
};

export const Banner: FC<BannerProps> = ({ className, classes: extClasses, children, in: inProp }) => {
  const classes = useStyles({
    classes: extClasses,
  });

  return (
    <Collapse in={inProp} className={clsx(classes.root, className)}>
      <Box className={classes.content}>
      {children}
      </Box>
    </Collapse>
  );
};
